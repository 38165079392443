import { Snackbar } from '@mui/material';
import { DataGrid, gridClasses, GridToolbar, ruRU } from '@mui/x-data-grid';
import { useState } from 'react';
import { FaEdit, FaCog } from 'react-icons/fa';
import DeviceParameter from '../../../model/device.parameter';
import DeviceParametersGroup from '../../../model/device.parameters.group';
import EcoModal from '../../eco_modal';
import ChangedParametersForGroupForm from './ChangedParametersForGroup';

export interface GroupsTableProps {
    groups: DeviceParametersGroup[];
    showUpdateForm: (group: DeviceParametersGroup) => void,
    deviceParameters: { [id: string]: DeviceParameter[]; };
}


const GroupsTable = ({ groups, showUpdateForm, deviceParameters }: GroupsTableProps) => {

    const [showParametersForm, setShowParametersForm] = useState<boolean>(false);
    const [groupId, setGroupId] = useState<number>(0);
    const [checkedParameters, setCheckedParameters] = useState<{ [id: number]: boolean }>({});
    const [actionNotificationOpen, setActionNotificationOpen] = useState(false);
    const [actionMessage, setActionMessage] = useState('');

    const handleCloseParametersForm = () => {
        setShowParametersForm(false)
    }

    const clickShowParametersForm = (group: DeviceParametersGroup) => {
        const checked: { [id: number]: boolean } = {}
        group.parameters.forEach((id: number) => {
            checked[id] = true
        })
        setCheckedParameters(checked)
        setGroupId(group.id)
        setShowParametersForm(true)
    }

    const notify = (message: string) => {
        setActionMessage(message);
        setActionNotificationOpen(true);
    };

    const afterSaving = () => {
        notify('Сохранение прошло успешно.')
        setTimeout(() => {
            window.location.reload()
        }, 1000)
    }

    const columns = [
        {
            field: 'id',
            headerName: '#',
            flex: 0.2,
        },
        {
            field: 'name',
            headerName: 'Название',
            flex: 1,
        },
        {
            field: 'label',
            headerName: 'Описание',
            flex: 1,
        },
        {
            field: 'short_label',
            headerName: 'Обозначение',
            flex: 0.7,
        },
        {
            field: 'units',
            headerName: 'Единицы измерения',
            flex: 0.7,
        },

        {
            field: 'parameters',
            headerName: 'Параметры',
            flex: 3,
            renderCell: (param: any) => {

                return (
                    <>
                        {Object.keys(deviceParameters).map((deviceTypeLabel) => {
                            return (<>
                                    {deviceParameters[deviceTypeLabel].map((parameter) => {
                                        const label = `${parameter.label} (${parameter.name}, ${parameter.units}, ${deviceTypeLabel})`
                                        const isVisible = param.row.parameters?.includes(parameter.id);
                                        if (!isVisible) return null
                                        return <>
                                            {label}<br />
                                        </>
                                    })}
                            </>
                            )
                        })
                        }
                    </>
                )
            },
        },
        {
            field: 'actions',
            headerName: 'Действия',
            flex: 0.5,
            renderCell: (param: any) => {
                return (
                    <p>
                        <FaEdit className="text-2xl inline cursor-pointer" onClick={() => showUpdateForm(param.row)} />
                        <FaCog className="text-2xl inline cursor-pointer" onClick={() => clickShowParametersForm(param.row)} />

                    </p>
                )
            },
        },
    ];

    return (
        <>
            <DataGrid
                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                getRowHeight={() => 'auto'}
                className='h-full cursor-pointer'
                rows={groups}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 100,
                        },
                    },
                    filter: {
                        filterModel: {
                            items: [],
                            quickFilterValues: [''],
                        },
                    },
                    columns: {
                        columnVisibilityModel: {
                        },
                    },
                }}
                pageSizeOptions={[5, 10, 50, 100]}
                disableRowSelectionOnClick
                disableColumnFilter
                disableDensitySelector
                disableColumnSelector
                disableColumnMenu
                disableVirtualization
                autoHeight={true}
                sx={{
                    [`& .${gridClasses.cell}`]: {
                        py: 1,
                    },
                }}
                slots={{
                    toolbar: GridToolbar,
                }}
                slotProps={{
                    toolbar: {
                        csvOptions: { disableToolbarButton: true },
                        printOptions: { disableToolbarButton: true },

                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },

                    },
                }}
            />

            <EcoModal
                key="add-parameters-to-group"
                open={showParametersForm}
                handleClose={handleCloseParametersForm}
                content={ChangedParametersForGroupForm}
                title={`Изменение списка параметров с устройств`}
                allParameters={deviceParameters}
                checkedParameters={checkedParameters}
                groupId={groupId}
                afterSaveSuccess={afterSaving}
            />

            <Snackbar
                open={actionNotificationOpen}
                autoHideDuration={2500}
                onClose={() => setActionNotificationOpen(false)}
                message={actionMessage}
            />
        </>
    );

}

export default GroupsTable;