import Nav from '../../nav';
import AdminHeader from '../header';
import SeismicEvents from './SeismicEvents';
import getSeismicEvents from "../../../api/seismic/get.seismic.events";
import { useEffect, useState } from "react";
import SeismicEvent from "../../../model/seismic.event";
import { Navigate } from "react-router-dom";
import { CircularProgress } from '@mui/material';




export default function SeismicPage() {
    const [seismicEvents, setSeismicEvents] = useState<SeismicEvent[] | null>([])
    const [loading, setLoading] = useState(false)

    const fetchData = async () => {
        setLoading(true)
        const seismicEvents = await getSeismicEvents()
        setSeismicEvents(seismicEvents)
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [])


    if (seismicEvents === null) {
        return (<Navigate to="/login" />)
    }


    return (
        <>
            <Nav />

            <section className="mt-0 min-h-[600px]">
                <div className="container px-5 admin-section">
                    <AdminHeader headerText={`Управление сейсмособытиями`} backUrl={`/lk`} />
                    {loading ? (
                        <div className="lr-loader-container h-[70vh]">
                            <CircularProgress size={150} />
                        </div>) : (
                        <SeismicEvents
                            seismicEvents={seismicEvents}
                        />
                    )}
                </div>
            </section>

        </>
    );
}