import { Component } from 'react';
import * as consts from '../../../../consts';
import Agro from './agro';
import BaseStation from './base_station';
import City from './city';
import Header from './header';
import PlantWidget from './PlantWidget';
import Soil from './soil';
import SoilDepth from './soil_depth';
import WsMeteo from './ws_meteo';
import GasWidget from './GasWidget';
import Footer from './Footer';
//import Seismic from '../../map/panel/widgets/seismic';
import Seismic from './seismic';


const deviceWidgets = {
	[consts.MODULE_TYPES.BASESTATION]: BaseStation,
	[consts.MODULE_TYPES.UE1]: City,
	[consts.MODULE_TYPES.BASECAM]: PlantWidget,
	[consts.MODULE_TYPES.WS]: WsMeteo,
	[consts.MODULE_TYPES.SOIL]: Soil,
	[consts.MODULE_TYPES.AGRO]: Agro,
	[consts.MODULE_TYPES.GAS]: GasWidget,
	[consts.MODULE_TYPES.GAS_V2]: GasWidget,
	[consts.MODULE_TYPES.SEISMO]: Seismic,

}

class WidgetModule extends Component {

	render() {
		let Widget;
		if (this.props.device.module_type_id in deviceWidgets) {
			Widget = this.props.device.soil_info ? SoilDepth : deviceWidgets[this.props.device.module_type_id];
		}

		if (!Widget) {
			return null
		}

		return (
			<div className="widget-div">
				<Header
					showDeviceById={this.props.showDeviceById}
					info={this.props.device}
				/>

				<Widget
					info={this.props.device}
					showDeviceById={this.props.showDeviceById}
				/>

				<Footer device={this.props.info} />
			</div>
		);
	}
}
export default WidgetModule;

