import { useEffect, useState } from "react";
import NoItems from "../../NoItems";
import { Snackbar } from "@mui/material";
import SeismicEventsTable from "./SeismicEventsTable";
import { useNavigate } from "react-router-dom";
import SeismicEvent from "../../../model/seismic.event";

interface SeismicEventsProps {
    seismicEvents: SeismicEvent[]
}


const SeismicEvents = ( {seismicEvents}: SeismicEventsProps ) => {
	const navigate = useNavigate();

    const [actionNotificationOpen, setActionNotificationOpen] = useState(false);
    const [actionMessage, setActionMessage] = useState('');
    const notify = (message: string) => {
            setActionMessage(message);
            setActionNotificationOpen(true);
    };


    const findEvents = () => {
        navigate(`/admin/seismic/findevents`);
    }

    return (
        <>
            <button className="lr-primary-button mb-3 align-bottom" onClick={findEvents}>
                Найти событие
            </button>
            {seismicEvents.length === 0 &&
                <NoItems text="Нет сейсмособытий" />
            }

            {seismicEvents.length != 0 &&
                <>
                    <SeismicEventsTable
                        events={seismicEvents}
                    />
                </>
            }
            <Snackbar
                open={actionNotificationOpen}
                autoHideDuration={2500}
                onClose={() => setActionNotificationOpen(false)}
                message={actionMessage}
            />

        </>
    );
}

export default SeismicEvents;