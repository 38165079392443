import axios from 'axios';
import * as consts from '../../consts';
import getHeaders from '../headers';
import DeviceParametersGroup from '../../model/device.parameters.group';
import DeviceParameter from '../../model/device.parameter';
import Device from '../../model/device';

export type AggregationType = 'day' | 'month' | 'year' | 'hour' | 'auto';

export interface DateForPeriodParameters {
    deviceParametersGroupId: DeviceParametersGroup['id'];
    dateFrom: Date;
    dateTo?: Date;
    ag_type?: AggregationType;
    group?: string;
}

export interface DeviceParamData {
    mstime: string;
    normal: number;
    raw: number;
}

export interface DeviceParamInfo {
    data: DeviceParamData[];
    diff: number;
    min: number;
}

export interface DeviceParametersDataInfo {
    data: Record<DeviceParameter['name'], DeviceParamInfo>;
    labels: string[];
}

export type DeviceParametersDataForPeriod = Record<Device['id_device'], DeviceParametersDataInfo>;

export const getDeviceParametersDataForPeriod = async (params: DateForPeriodParameters): Promise<DeviceParametersDataForPeriod> => {
    const headers = getHeaders();

    const dateFromTimestamp = Math.floor(params.dateFrom.getTime() / 1000);
    const dateToTimestamp = Math.floor((params.dateTo ?? new Date()).getTime() / 1000);

    const result = await axios({
        method: "GET",
        url: `${consts.BASE_API_URL}/player/data_for_period`,
        headers,
        params: {
            device_parameter_group_id: params.deviceParametersGroupId,
            dt_start: dateFromTimestamp,
            dt_end: dateToTimestamp,
            ag_type: params.ag_type,
            group: params.group
        },
    });

    return result.data.result as DeviceParametersDataForPeriod;
};
